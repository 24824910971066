import React from 'react';

/**
 * Site footer component
 */
const Footer = () => (
  <footer>
  </footer>
);

export default Footer;
